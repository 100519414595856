import { AppState, HomePageGoaAppState } from '../../../initialStateRegistration';
import {
    SectionNames,
    HomePageGoaTopSection,
    HomePageGoaMinorSections,
    HomePageImageLinksSections,
    HomePageGoaBannerSection,
    HomePageGoaWithAdditionalLinksSection,
} from './homePageGoa.initialState';

export const getHomePage = (state: AppState) => (state as HomePageGoaAppState).template;

export const getSectionDataByKey = (key: SectionNames) => (state: AppState) => getHomePage(state)[key];

export const getSectionImagesWithAdditionalLinksByKey = (key: HomePageGoaWithAdditionalLinksSection) => (state: AppState) => getHomePage(state)[key].imagesWithAdditoinalLinks;

export const getSectionArticlesByKey = (key: HomePageGoaMinorSections | HomePageGoaTopSection) => (state: AppState) => getHomePage(state)[key].articles;

export const getSectionTitleByKey = (key: HomePageGoaMinorSections | HomePageImageLinksSections) => (state: AppState) => getHomePage(state)[key].sectionTitle;

export const getSectionImageLinks = (key: HomePageImageLinksSections) => (state: AppState) => getHomePage(state)[key].imageLinks;

export const getSectionBanner = (key: HomePageGoaBannerSection) => (state: AppState) => getHomePage(state)[key].image;

export const getSectionBannerLink = (key: HomePageGoaBannerSection) => (state: AppState) => getHomePage(state)[key].link;

export const getMoreButtonTextByKey = (key: HomePageImageLinksSections) => (state: AppState) => getHomePage(state)[key].moreButtonText;

export const getMetadataDescription = (state: AppState) => getHomePage(state).metadataDescription;

export const getMetadataTitle = (state: AppState) => getHomePage(state).metadataTitle;

export const getMetadataImage = (state: AppState) => getHomePage(state).metadataImage;

export const getHreflangData = (state: AppState) => getHomePage(state).hreflangData;

export const getFeaturedArticle = (key: HomePageGoaTopSection) => (state: AppState) => getHomePage(state)[key].featuredArticle;
